import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  FormGroup,
  Input,
  Typography,
} from '@mui/material';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { matchSorter } from 'match-sorter';

import NestedCheckbox from '@/components/FiltersSidebar/NestedCheckbox';
import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/magnifyingGlass.svg';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

function Filter({ label, filterKey, options, search }) {
  const [isOpen, setIsOpen] = useState(
    () => window?.history?.state?.[filterKey] ?? false,
  );
  const [searchValue, setSearchValue] = useState('');
  const { parameters } = useQueryParameters();

  useEffect(() => {
    window.history.replaceState(
      { ...window.history.state, [filterKey]: isOpen },
      '',
    );
  }, [filterKey, isOpen]);

  if (!Array.isArray(options)) {
    return null;
  }

  const filteredOptions = search
    ? matchSorter(options, searchValue, {
        keys: ['name'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    : options;

  const checkedCount = parameters[filterKey]?.length ?? 0;

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => setIsOpen((x) => !x)}
      disableGutters
      sx={{
        // add a constant border
        borderTop: '1px solid rgba(0,0,0,0.12)',
        // remove the conditional border
        [`&:before`]: {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        data-pendo-id={`filter-disclosure-${label}`}
        sx={{
          [`& .${accordionSummaryClasses.content}`]: {
            justifyContent: 'space-between',
            marginRight: 1,
          },
        }}
      >
        <Box>{label}</Box>
        {checkedCount > 0 && (
          <Chip
            size="small"
            label={checkedCount}
            color="primary"
            sx={{ lineHeight: 'normal' }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        {search && (
          <StyledInput
            inputProps={{
              'aria-label': label,
            }}
            id="search"
            name={label}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            placeholder={`Search ${label.toLowerCase()}\u2026`}
            startAdornment={<StyledMagnifyingGlassIcon />}
            type="search"
            data-pendo-id={`filter-search-${label}`}
          />
        )}

        {search && filteredOptions.length === 0 && (
          <Typography sx={{ color: 'status.errorForeground' }}>
            No {label.toLowerCase()} found.
          </Typography>
        )}
        <FormControl>
          <FormGroup>
            {filteredOptions.map((option) => (
              <div data-pendo-id={`filter-checkbox-${label}`} key={option.id}>
                <NestedCheckbox
                  filterKey={filterKey}
                  name={option.name}
                  id={option.id}
                  options={option.options}
                  pendoIdPrefix={`filter-checkbox-${label}-`}
                />
              </div>
            ))}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

Filter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  search: PropTypes.bool,
};

Filter.defaultProps = {
  options: undefined,
  search: false,
};

const StyledInput = styled(Input)`
  margin: 0 auto 12px;
  padding: 6px;
  width: 100%;
`;

const StyledMagnifyingGlassIcon = styled(MagnifyingGlassIcon)`
  width: 17px;
  margin-right: 6px;
  color: ${(p) => p.theme.palette.icon.secondary};
`;

export default Filter;
