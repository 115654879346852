import { Box, Paper, Typography } from '@mui/material';

function EvidenceBasedRecommendationsHeader() {
  return (
    <Box sx={{ justifyContent: 'space-between' }}>
      <Paper
        elevation={0}
        sx={{
          p: 1,
          display: 'flex',
          backgroundColor: 'background.tableZebra',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">Evidence-based Recommendations</Typography>
        <Typography sx={{ marginTop: 1 }}>
          Provided by <b>Vytalize</b>
        </Typography>
      </Paper>
    </Box>
  );
}

EvidenceBasedRecommendationsHeader.propTypes = {};

export default EvidenceBasedRecommendationsHeader;
