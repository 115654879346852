import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export default function Filter({ label, filterKey, children }) {
  const [isOpen, setIsOpen] = useState(
    () => window?.history?.state?.[filterKey] ?? true,
  );

  useEffect(() => {
    window.history.replaceState(
      { ...window.history.state, [filterKey]: isOpen },
      '',
    );
  }, [filterKey, isOpen]);

  return (
    <Accordion expanded={isOpen} onChange={() => setIsOpen((x) => !x)}>
      <AccordionSummary>{label}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

Filter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
